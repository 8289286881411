.bulk-edit-first-row-field {
    width: 20rem;
}

.update-responsibility-matrix-main-wrapper {
    padding: 1rem;
    padding-bottom: 2rem;
    border-radius: 0.5rem; 
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); 
    width: 100%;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(234, 237, 237);
    color: black;
    margin: 4rem 0;
}

.update-responsibility-matrix-add-wrapper {
    margin-top: 2rem;
    margin-bottom: 8rem;
}

.update-responsibility-matrix-added-wrapper {
    margin-bottom: 4rem;
}

.addedAttribute {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-top: 2rem;
}

.addedAttribute-attribute-select-wrapper, .addedAttribute-new-value-input-wrapper {
    width: 70%;
}

.addedAttribute-new-value, .addedAttribute-attribute {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.addedAttribute-remove-wrapper {
    width: 20rem;
    grid-column: 4;
}


.addAttribute {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-top: 2rem;
}

.addAttribute-select-attribute, .addAttribute-value-selection-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.addAttribute-select-attribute-selection-wrapper, .addAttribute-value-selection-field-wrapper {
    width: 60%;
}

.addAttribute-select-attribute-title-wrapper {
    color: black;
    font-size: 14px;
}

.addAttribute-add-button {
    grid-column: 4;
}

.selection-criteria-actions-wrapper {
    display: flex;
    justify-content: center;
    gap: 8rem;
}

.selection-criteria-wrapper {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); 
    width: 100%;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(234, 237, 237);
    color: black;
    padding: 1rem 0.5rem;
}

.selection-criteria-title-wrapper {
    width: 100%;
    color: black;
}

.selection-criteria-wrapper div[class*="awsui_icon-container"] {
    color: black;
}

.update-responsibility-matrix-action-buttons-wrapper {
    margin-top: 4rem;
    display: flex;
    gap: 8rem;
    justify-content: center;
    padding-bottom: 5rem;
}

.status-green {
    color: green;
}
.status-red {
    color: red;
}
.status-orange {
    color: darkorange;
}

.filteredKeysTable-export-keys-button-wrapper > a {
    color: black !important;
    text-decoration: none;
}

#modal-dialog-wrapper div[class*="awsui_dialog"] {
    max-width: 75% !important;
    margin: 0 auto;
}

.Dialog-info-text {
    padding-bottom: 1rem;
    text-align: center;
}

.bulkKeyEdit-keys-table {
    overflow-y: auto;
    margin-bottom: 4rem;
    padding: 0.5rem;
    margin-top: 2rem;
}

.update-responsibility-matrix-add-wrapper-title, .update-responsibility-matrix-added-wrapper-title {
    text-align: left;
    border-bottom: 1px solid black;
}