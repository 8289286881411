tbody > tr {
    cursor: pointer;
}

tbody > tr:hover {
    background-color: lightgrey;
}

.Drafts {
    padding: 2rem;
}

.Drafts-name-cols {
    color: #0d6efd;
    text-decoration: none;
}

.Drafts-name-cols:hover {
    text-decoration: underline;
}