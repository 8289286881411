.reset-button{
    width: 50px;
    font-size: 12px;
}
.select-all-button {
    font-size: 12px;
}

.create-keys-multi-select-filter-wrapper > div > div:nth-child(2):hover {
    overflow: auto;
}

.multi-select-title > div > label {
    color: black !important;
}

.checkpointView-single-select-label-wrapper div > label{
    color: black !important;
}