.checkpoint-button{
    font-size: 13px;
}

.delegate-text{
    font-size: 1.4rem;
}

.selection-criteria-action-buttons-wrapper {
    padding-top: 3rem;
    padding-right: 4rem;
    display: flex;
    gap: 8rem;
    justify-content: center;
}

.Checkpoint-top-row {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 4rem;
    margin-bottom: 4rem;
    align-items: center;
}

.delete-checkpoint-wrapper{
    margin-top: 2rem;
}

.checkpoint-final-row-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
    padding-bottom: 4rem;
    gap: 4rem;
}

.checkpointReport-action-buttons-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: center;
    margin-top: 4rem;
}

.checkpointReport-action-buttons-copy-alias button,.checkpointReport-action-buttons-copy-email button,
    .checkpointReport-action-buttons-csv button {
    width: 25rem;
    text-align: center !important;
    background-color: #ec7211 !important;
    border-color: #ec7211 !important;
}

.checkpointReport-action-buttons-copy-alias button:hover,.checkpointReport-action-buttons-copy-email button:hover,
    .checkpointReport-action-buttons-csv button:hover {
    background-color: darkorange !important;
    border-color: darkorange !important;
}

.checkpoint-RaciTable {
    height: 50vh;
    overflow-y: auto;
    margin-bottom: 4rem;
    padding: 0.5rem;
}

.checkpointView-selection-criteria-wrapper {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); 
    width: 100%;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(234, 237, 237);
    color: black;
    padding: 1rem 0.5rem;
}

.checkpointView-selection-criteria-title-wrapper {
    color: black;
    width: 100%;
}

.checkpointView-selection-criteria-wrapper div[class*="awsui_icon-container"] {
    color: black;
}

.selection-criteria-error-wrapper {
    padding-top: 2rem;
    text-align: center;
}

.selection-criteria-error {
    color: red;
}

.selection-criteria-error-text {
    padding-left: 1rem;
}

.DeleteCheckpointDialogContent-info-text {
    text-align: center;
}