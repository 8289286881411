.menu-items {
    font-size: 14px;
}

.nav-dropdown {
    padding: .5rem 1rem;
}

.navbar-title {
    font-size: 18px;
}

.navbar-dropdown {
    width: 15%;
}

.navbar-margin {
    margin-left: 15px;
}
/* Turn User Help dropdown same color as other text in navbar */
div.dropdown.nav-item > a {
    color: rgba(255, 255, 255, 0.55);
}
/* Turn User Help dropdown same color as other text in navbar when hovering*/
div.dropdown.nav-item:hover > a {
    color: rgba(255, 255, 255, 0.75);
}

.dropdown-item {
    font-size: 14px;
}

.message-bar-text{
    font-size: 1.3rem;
}

.Checkpoint-top-menu {
    max-width: unset;
    padding: 0 1.5rem;
}

.blue-button-theme-enabled-state {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    color: #fff !important;
  }

  .blue-button-theme-enabled-state:hover {
    background-color: mediumblue !important;
    border-color: mediumblue !important;
  } 
  
.blue-button-theme-disabled-state {
    background-color: #9CA3AF !important; 
    border-color: #9CA3AF !important;
    color: rgb(241 245 249) !important;
}

.top-navbar {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.navbar-menu-item {
    color: white;
    text-decoration: none;
    font-size: 14px;
    margin-top: 2px;
}

.navbar-menu-item:hover {
    color: lightblue;
}

.navbar-menu-item-active {
    color: deepskyblue !important;
}

div.nav-dropdown.nav-item.show.dropdown > div.dropdown-menu.show {
    right: 0;
    top: 3rem;
}