.Changelog-table-wrapper {
    padding: 2rem;
}

.Changelog-header-content-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 2rem;
}

.Changelog-header-dates-wrapper, .Changelog-header-date-wrapper {
    display: flex;
    align-items: baseline;
}

.Changelog-header-date-wrapper, .Changelog-header-date-wrapper > div {
    padding: 0 1rem;
}

.Changelog-header-select-wrapper {
    width: 10%;
    min-width: 5rem;
}

.Changelog-header-error-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.Changelog-summary-view-dialog-wrapper {
    font-size: 1.35rem;
}

.Changelog-summary-view-dialog-wrapper > table {
    width: 100%;
    border-collapse: collapse;
    font-family: var(--font-family-base-ua1f64, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

.Changelog-summary-view-dialog-wrapper > table  tr > th, .Changelog-summary-view-dialog-wrapper > table  tr > td {
    text-align: left;
    padding: 2rem;
}

.Changelog-summary-view-info-wrapper {
    display: flex;
    justify-content: left;
    padding-left: 1.5rem;
}

.Changelog-summary-view-info {
    padding-left: 1rem;
}

.Changelog-cols {
    color: #0d6efd;
    text-decoration: none;
}

.Changelog-cols.disabled {
    color: unset;
    pointer-events: none;
}

.Changelog-info-expandable-section-wrapper {
    width: 100%;
    max-height: 75vh;
    width: 60rem;
    overflow: auto;
}

.Changelog-info-expandable-section-wrapper > table {
    width: 100%;
}

.Changelog-info-expandable-section-wrapper > table tr {
    line-height: 2.25rem;
}

.Changelog-info-section-cell {
    width: 20rem;
    white-space: break-spaces;
}

.Changelog-summary-view-dialog-wrapper > table > tr > td {
    line-height: 3rem;
}

.Changelog-table-wrapper [role="table"] > tbody  > tr > td:nth-child(4) {
    width: 25%;
}

.Changelog-table-wrapper [role="table"] > tbody  > tr > td:nth-last-child(1) {
    width: 15%;
}

.Changelog-summary-view-content-wrapper {
    display: flex;
    flex-wrap: nowrap;
}

.Changelog-summary-view-content-item-wrapper {
    padding: 1rem 1.5rem;
    max-width: 30rem;
    min-width: 20rem;
    overflow-x: auto;
}

.Changelog-summary-view-content-item-wrapper > div {
    padding: 1rem 0;
}

.Changelog-confirmation-dialog .MuiDialog-container .MuiPaper-root.MuiDialog-paper {
    max-width: 90%;
}

.Changelog-summary-view-content-wrapper {
    line-height: 3rem;
    word-break: break-all;
}

.Changelog-table-header-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.Changelog-table-header-pagination-dropdown-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    width: 25%;
}
.Changelog-table-header-pagination-dropdown-wrapper > h4 {
    padding-right: 1.5rem;
}

.Changelog-filter-wrapper {
    display: flex;
}

.Changelog-filter-wrapper > div {
    padding-right: 1rem;
}

.Changelog-filter-text-wrapper {
    width: 40%;
}

.Changelog-actionedByUser-select-wrapper {
    width: 20rem;
}

.Changelog-additional-info-section-wrapper {
    width: 35rem;
    white-space: break-spaces;
    overflow: auto;
    max-height: 75vh;
    line-height: 2.5rem;
}

.Changelog-cols ~ .Changelog-cols::before {
    content: ',';
}