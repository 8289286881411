tbody > tr {
    cursor: pointer;
}

tbody > tr:hover {
    background-color: lightgrey;
}

.manage-checkpoints {
    padding: 2rem;
}

.manage-checkpoints-program-name-cols {
    color: #0d6efd;
    text-decoration: none;
}

.manage-checkpoints-program-name-cols:hover {
    text-decoration: underline;
}

.manage-checkpoints-header {
    display: flex;
    justify-content: space-between;
}

.manage-checkpoints-dashboard-wrapper {
    position: relative;
}

.manage-checkpoints-spinner-wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    text-align: center;
}