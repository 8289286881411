body {
    font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
}

.home-cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4rem;
    width: 75%;
    margin: 0 auto;
    align-content: center;
    margin-top: 5vh;
    padding-bottom: 10rem;
}

.home-card {
    padding: 0.5rem;
    border-radius: 0.5rem;  
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid rgb(234, 237, 237);
    color: black;
    text-align: center;
    align-content: center;
    height: 25rem;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 1px 1px 1px 1px gray;
    background-repeat: no-repeat;
    background-size: contain;
    background-blend-mode: darken;
    background-position: center;
}

.home-card:hover {
    box-shadow: 3px 3px 5px 3px gray;
    cursor: pointer;
}

.home-card-title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-card-subtitle {
    font-size: 12.25px;
    padding-top: 1.5rem;
    position: absolute;
    bottom: 1rem;
}

#Home-title {
    text-align: center;
    margin-top: 5vh;
}

#Home-title > h2 {
    font-weight: 600;
}   
